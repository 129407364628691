import React from "react";
import HighlightIcon from '@mui/icons-material/Highlight';

function Header() {
    return (
         <div>
            <header>
                <h1><HighlightIcon />Keeper</h1>
            </header>
        </div>
    )
}

export default Header;